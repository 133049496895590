import React from 'react'
import { Text, Box } from 'theme-ui'
import { Layout, Stack } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const styles = {
    container: {
        textAlign: 'center',
        margin: 'auto',
        padding: '60px 20px 50px 20px',
    },
    title: {
        fontWeight: 700,
        fontSize: 40,
        color: '#2A205E',
        margin: 0,
        marginBottom: '100px',
        '@media (min-width: 1024px)': {
            fontSize: 70,
        }
    },
    loader: {
        width: '25px',
        height: '25px',
        borderRadius: '50%',
        display: 'block',
        margin: '15px auto',
        position: 'relative',
        color: '#2A205E',
        boxSizing: 'border-box',
        animation: 'animloader 1s linear infinite',
        
        '@keyframes animloader': {
            '0%':   { boxShadow: '28px 0 0 -2.5px,  80px 0 0 -2.5px,  -28px 0 0 -2.5px,  -80px 0 0 -2.5px' },
            '25%':  { boxShadow: '28px 0 0 -2.5px,  80px 0 0 -2.5px,  -28px 0 0 -2.5px,  -80px 0 0 2.5px' },
            '50%':  { boxShadow: '28px 0 0 -2.5px,  80px 0 0 -2.5px,  -28px 0 0 2.5px,  -80px 0 0 -2.5px' },
            '75%':  { boxShadow: '28px 0 0 2.5px,  80px 0 0 -2.5px,  -28px 0 0 -2.5px,  -80px 0 0 -2.5px' },
            '100%': { boxShadow: '28px 0 0 -2.5px,  80px 0 0 2.5px,  -28px 0 0 -2.5px,  -80px 0 0 -2.5px' },
        }
    }
}

const Page404 = props => {

    return (
        <Layout {...props}>
          <Seo title='Page Not Found' />
          <Divider />
          <Stack>
            <Box style={styles.container}>
                <Text style={styles.title}>
                    Te estamos redirigiendo en unos segundos...
                </Text>

                <Text variant='span' style={styles.loader}></Text>
            </Box>
          </Stack>
        </Layout>
      )
    
}

export default Page404
